<template>
    <Modal label="Data Rekening">
        <div class="y-inputField">
            <div class="y-labelText">
                Bank
            </div>
            <div class="y-inputPlace y-hasIcon-right">
                <div class="y-select-hasIcon" style="width: 100%">
                    <select class="y-select" v-model="item.id_bank">
                        <option class="y-option"
                            v-for="(l,i) in listBank" 
                            :key="`list-data-${i}`"
                            :value="l.id"
                        >
                            {{l.name}}
                        </option>
                    </select>
                    <div class="y-select-icon">
                        <img :src="require('@/assets/icons/others/arrowDown.svg')" alt="">
                    </div>
                </div>
            </div>
            <div
                class="y-errorText y-red-text"
                :style="error.id_bank ? 'opacity: 1 !important;': ''"
            >
                {{errorMsg.id_bank}}
            </div>
        </div>

        <InputCustom
            v-model="item.name"
            :error="error.name"
            :errorMsg="errorMsg.name"
            @onFocus="resetError('name')"
            placeholder="Nama"
            label="Nama Rekening"
        >
        
        </InputCustom>

        <InputCustom
            v-model="item.no_rek" 
            :error="error.no_rek"
            :errorMsg="errorMsg.no_rek"
            @onFocus="resetError('no_rek')"
            placeholder="Nomor Rekening"
            label="Nomor Rekening"
        >
        </InputCustom>
        
        <template #actionButton>
            <ButtonCustom
                :disabled="loading"
                type="secondary"
                size="sm"
                class="r-mlr-10"
                @click.prevent="resetData()"
            >
                Batal
            </ButtonCustom>
            
            <ButtonCustom
                :disabled="loading"
                type="primary"
                size="sm"
                class="r-mlr-10"
                @click.prevent="sendData()"
            >
                {{inputType}}
            </ButtonCustom>
        </template>
    </Modal>
</template>
<script>
import common from '@/mixins/function/common'
import { mapGetters, mapActions } from 'vuex'
import Modal from '@/components/Helper/Modal'
import validation from '@/mixins/function/validation'
export default {
    mixins: [
        common,
        validation
    ],
    components: {
        Modal,
    },
    mounted() {
        this.loadBanks = true
    },
    props: {
        inputType: {
            require: true,
        }
    },
    computed: {
        ...mapGetters({
            item: 'rekening/getItem',
            listBank: 'bank/getBanks'
        })
    },
    data: () => ({
        loadBanks: false,
        error: {
            name: null,
            id_bank: null,
            no_rek: null,
        },
        errorMsg: {
            name: 'Tidak boleh kosong',
            id_bank: 'Tidak boleh kosong',
            no_rek: 'Tidak boleh kosong',
        },
        loading: false,
    }),
    methods: {
        ...mapActions({
            store: 'rekening/store',
            update: 'rekening/update',
        }),
        resetData() {
            this.$emit('resetData')
        },
        validation() {
            var error = 0
            var field = ['name', 'id_bank', 'no_rek']
            for (var i = 0; i < field.length; i++){
                if(this.item[field[i]] === null || this.item[field[i]]  === ''){
                    this.error[field[i]] = true
                    error += 1;
                }else{
                    this.error[field[i]] = false
                }
            }
            if(this.item.no_rek !== null && this.item.no_rek !== ""){
                if(!(/^\d+$/.test(this.item.no_rek))){
                    this.error.no_rek = true
                    this.errorMsg.no_rek = "Hanya boleh angka"
                    error += 1;
                } else{
                    this.error.no_rek = false
                }
            }
            return error
        },
        async sendData() {
            if (this.validation() === 0) {
                this.loading = true
                if (this.inputType == 'Tambah') {
                    await this.store(this.item)
                } else {
                    await this.update(this.item)
                }
                this.loading = false
                const response = this.getResponse('rekening')
                this.showSnackbar({
                    type: response.status == 1 ? 'success' : 'error',
                    text: response.msg
                })

                if (response.status === 1) {
                    this.resetData()
                    this.$emit('getData')
                }
            } else {
                this.showSnackbar ({
                    type: 'error',
                    text: 'Mohon periksa kembali'
                })
            }
        }
    }
}
</script>