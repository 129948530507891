<template>
    <div>
        player online
    </div>
</template>

<script>
export default {
    
}
</script>